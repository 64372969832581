import { Document, Image, Page, View, Text as PdfText, Font } from "@react-pdf/renderer";
import { Course } from "protobuffer-ts/dist/class_service/course";
import { Homeroom } from "protobuffer-ts/dist/class_service/homeroom";
import { PdfAttendanceCount } from "../../models/attendance";
import { ReportEntry } from "../../models/report_entry";
import { learningSkills } from "../../models/semester";
import { Grades, Student } from "../../models/student";
import { utils } from "../../utils/array_utils";
import { CourseRowPdf } from "./CourseRowPdf";
import PdfStylesheet from "./PdfStylesheet";

// Define styles for the PDF document
const styles = PdfStylesheet

Font.registerHyphenationCallback(word => [word]);

Font.registerEmojiSource({
	format: 'png',
	url: 'https://cdnjs.cloudflare.com/ajax/libs/twemoji/14.0.2/72x72/',
});

// Register Roboto fonts
Font.register({
	family: 'Roboto',
		fonts: [
	  { src: '/fonts/Roboto-regular.woff', fontWeight: 'normal' },
	  { src: '/fonts/Roboto-700.woff', fontWeight: 'bold' },
	],
  });

// School information (constant data)
const schoolInfo = {
	School: 'Alfajrul Bassem Academy',
	Address: '700 Kerr St Oakville, ON, L6K 3E1',
	Telephone: '+1 (905) 514-9995',
	Email: 'info@alfajrulbassem.com',
	Principal: 'Rana Hamoui',
};

interface ReportCardProps { 
	student: Student
	homeroom: Homeroom
	courses: Course[] 
	teachers: string 
	report: ReportEntry
	coursesReports: ReportEntry[]
	days: PdfAttendanceCount
}

export const ReportCardPdf: React.FC<ReportCardProps> = ({ student, homeroom, courses, teachers, report, coursesReports, days }) => {
	// Convert the subjects to an array of 5 items for each, each 5 will be rendered in a separated page
	const coursesChunks = utils.chunkArray(courses, 5) as Course[][];

	// Homeroom Learning Skills Data
	const learningSkillsMap: { [key: string]: string } = {};
	homeroom.semester?.reportLayout?.learningSkills.forEach((skill) => {
		if (skill.id) {
			learningSkillsMap[skill.id.id] = skill.title;
		}
	});

	// Mapping Learning Skills to their Values in Desired Format
	const grades: { [key: string]: string } = {};
	report.learning_skills.forEach((learningSkill) => {
		const skillId = learningSkill.learning_skill_id.$oid;
		const skillTitle = learningSkillsMap[skillId];
		const skillValue = learningSkill.mark;
		grades[skillTitle] = skillValue;
	});

	return (
		<Document>
			<Page style={styles.page} wrap>
				<View style={styles.headerContainer}>
					{/* Logo Column */}
					<View style={styles.headerColumn}>
						<Image style={styles.logo} src="/ab-logo.jpeg" />
					</View>
					{/* Title Column */}
					<View style={styles.headerColumn}>
						<PdfText style={styles.header}>Student Report Card</PdfText>
					</View>
					{/* Empty Column */}
					<View style={styles.headerColumn} />
				</View>

				{/* Student Information Section Title */}
				<View style={styles.sectionTitleContainer}>
					<PdfText style={styles.sectionTitleText}>Student Information</PdfText>
				</View>

				{/* Student Information and School Information in a Specific Order */}
				<View style={styles.tableContainer}>
					{/* First Row */}
					<View style={[styles.cell, styles.cellHalfWidth]}>
						<PdfText>
							<PdfText style={styles.label}>Student Name: </PdfText>
							<PdfText style={styles.value}>{`${student.profile.first_name} ${student.profile.last_name}`}</PdfText>
						</PdfText>
					</View>
					<View style={[styles.cell, styles.cellHalfWidth]}>
						<PdfText>
							<PdfText style={styles.label}>OEN: </PdfText>
							<PdfText style={styles.value}>{student.profile.OEN}</PdfText>
						</PdfText>
					</View>

					{/* Second Row */}
					<View style={[styles.cell, styles.cellHalfWidth]}>
						<PdfText>
							<PdfText style={styles.label}>Grade: </PdfText>
							<PdfText style={styles.value}>{Grades.get(student.profile.grade)}</PdfText>
						</PdfText>
					</View>
					<View style={[styles.cell, styles.cellHalfWidth]}>
						<PdfText>
							<PdfText style={styles.label}>Teacher: </PdfText>
							<PdfText style={styles.value}>{teachers}</PdfText>
						</PdfText>
					</View>

					{/* Third Row (Four Columns) */}
					<View style={[styles.cell, styles.cellQuarterWidth]}>
						<PdfText>
							<PdfText style={styles.label}>Days Absent: </PdfText>
							<PdfText style={styles.value}> {days.days_count['Absent'] ?? '0'} </PdfText>
						</PdfText>
					</View>
					<View style={[styles.cell, styles.cellQuarterWidth]}>
						<PdfText>
							<PdfText style={styles.label}>Total Days Absent: </PdfText>
							<PdfText style={styles.value}>{days.total_days_count['Absent'] ?? '0'}</PdfText>
						</PdfText>
					</View>
					<View style={[styles.cell, styles.cellQuarterWidth]}>
						<PdfText>
							<PdfText style={styles.label}>Times Late: </PdfText>
							<PdfText style={styles.value}>{days.days_count['Late'] ?? '0'}</PdfText>
						</PdfText>
					</View>
					<View style={[styles.cell, styles.cellQuarterWidth]}>
						<PdfText>
							<PdfText style={styles.label}>Total Times Late: </PdfText>
							<PdfText style={styles.value}>{days.total_days_count['Late'] ?? '0'}</PdfText>
						</PdfText>
					</View>

					{/* Fourth Row */}
					<View style={[styles.cell, styles.cellHalfWidth]}>
						<PdfText>
							<PdfText style={styles.label}>School: </PdfText>
							<PdfText style={styles.value}>{schoolInfo.School}</PdfText>
						</PdfText>
					</View>
					<View style={[styles.cell, styles.cellHalfWidth]}>
						<PdfText>
							<PdfText style={styles.label}>Email: </PdfText>
							<PdfText style={styles.value}>{schoolInfo.Email}</PdfText>
						</PdfText>
					</View>

					{/* Fifth Row (Full Width for Address) */}
					<View style={[styles.cell, styles.cellFullWidth]}>
						<PdfText style={styles.label}>Address:</PdfText>
						<PdfText style={styles.value}>{schoolInfo.Address}</PdfText>
					</View>

					{/* Sixth Row */}
					<View style={[styles.cell, styles.cellHalfWidth]}>
						<PdfText>
							<PdfText style={styles.label}>Principal: </PdfText>
							<PdfText style={styles.value}>{schoolInfo.Principal}</PdfText>
						</PdfText>
					</View>
					<View style={[styles.cell, styles.cellHalfWidth]}>
						<PdfText>
							<PdfText style={styles.label}>Telephone: </PdfText>
							<PdfText style={styles.value}>{schoolInfo.Telephone}</PdfText>
						</PdfText>
					</View>
				</View>

				{/* Adding Spacing Between Sections */}
				<View style={styles.sectionSpacing} />

				{/* Learning Skills and Work Habits Section */}
				<View style={styles.skillsHeaderContainer}>
					<PdfText style={styles.skillsHeaderText}>Learning Skills and Work Habits</PdfText>
				</View>

				<View style={styles.skillsSubHeaderContainer}>
					<PdfText style={styles.skillsSubHeaderText}>E – Excellent G – Good S – Satisfactory N – Needs Improvement</PdfText>
				</View>

				{/* Learning Skills Section - Two Columns */}
				<View style={styles.skillsColumnContainer}>
					{learningSkills.map(({ skill, descriptions }, index) => (
						<View key={index} style={styles.skillItemContainer}>
							<View style={styles.skillItem}>
								<PdfText style={styles.skillTitle}>{skill}</PdfText>
								<PdfText style={styles.skillGrade}>{grades[skill]?.[0] || 'N/A'}</PdfText>
							</View>
							<View style={styles.skillDescriptionContainer}>
								{descriptions.map((description, i) => (
									<PdfText key={i} style={styles.bulletPoint}>• {description}</PdfText>
								))}
							</View>
						</View>
					))}
				</View>

				{/* Comment Section */}
				<View style={styles.skillsHeaderContainer}>
					<PdfText style={styles.skillsHeaderText}>Comment</PdfText>
				</View>

				{/* New Comment Section with Border and Font Size 8 */}
				<View style={styles.commentSection}>
					<PdfText>
						{report.comment}
					</PdfText>
				</View>

				{/* Footer */}
				<View style={styles.footer} fixed>
					<View style={styles.footerColumnLeft}>
						<PdfText>ABA R01</PdfText>
						<PdfText>{new Date().toLocaleDateString('en-US', { month: 'short', day: '2-digit', year: 'numeric' })}</PdfText>
					</View>
					<View style={styles.footerColumnCenter}>
						<PdfText>app.bassemlabs.com</PdfText>
						<PdfText>info@alfajrulbassem.com</PdfText>
					</View>
					<View style={styles.footerColumnRight}>
						<PdfText>Tel: +1 (905) 514-9995</PdfText>
						<PdfText>Page 1 of {coursesChunks.length + 1}</PdfText>
					</View>
				</View>
			</Page>

			{/* New Page for Subjects and Progress Grid */}
			{coursesChunks.map((chunk, pageIndex) => (
				<Page key={pageIndex} style={styles.page} wrap>
					{/* Report Card Section */}
					<View style={styles.skillsHeaderContainer}>
						<PdfText style={styles.skillsHeaderText}>Report Card</PdfText>
					</View>

					{/* Grid Header */}
					<View style={styles.gridContainer}>
						<View style={[styles.gridCell, styles.subjectCol, styles.flexCenter]}>
							<PdfText style={styles.label}>Subjects</PdfText>
						</View>
						<View style={[styles.gridCell, styles.smallCol, { height: 100 }]}>
							<PdfText style={styles.verticalText}>Progressing With-Difficulty</PdfText>
						</View>
						<View style={[styles.gridCell, styles.smallCol]}>
							<PdfText style={styles.verticalText}>Progressing Well</PdfText>
						</View>
						<View style={[styles.gridCell, styles.smallCol]}>
							<PdfText style={styles.verticalText}>Progressing Very-Well</PdfText>
						</View>
						<View style={[styles.gridCell, styles.largeCol, styles.flexCenter]}>
							<PdfText style={styles.label}>Strengths/Next Steps for Improvement</PdfText>
						</View>
					</View>

					{/* Example Rows for Subjects with Schema */}
					{chunk.map(course => (
						<CourseRowPdf course={course} reportEntry={coursesReports.find(r => r.course?.$oid === course.id?.id)!} />
					))}

					{/* Footer */}
					<View style={styles.footer} fixed>
						<View style={styles.footerColumnLeft}>
							<PdfText>ABA R01</PdfText>
							<PdfText>{new Date().toLocaleDateString('en-US', { month: 'short', day: '2-digit', year: 'numeric' })}</PdfText>
						</View>
						<View style={styles.footerColumnCenter}>
							<PdfText>app.bassemlabs.com</PdfText>
							<PdfText>info@alfajrulbassem.com</PdfText>
						</View>
						<View style={styles.footerColumnRight}>
							<PdfText>Tel: +1 (905) 514-9995</PdfText>
							<PdfText>Page {pageIndex + 2} of {coursesChunks.length + 1}</PdfText>
						</View>
					</View>
				</Page>
			))}
		</Document>
	);
};