import {ObjectId} from "../utils/ObjectId";
import {DateTime} from "../utils/DateTime";
export class Student {
    _id: ObjectId;
    id: string;
    username: string;
    status: StudentStatus;
    profile: StudentProfile;
    family_id: ObjectId;
    health: Health;
    office_information: OfficeInformation;
    documents: Array<Document>;
    form_data: FormDataInterface;
    consent: Consent;
    donation: Donation;
    uniform: StudentUniform;
    forms: Array<StudentForm>
    constructor(data: any){
        this._id = data._id
        this.id = data.id
        this.username = data.username

        this.status = data.status

        this.profile = data.profile
        this.profile.date_of_birth = new DateTime(data.profile.date_of_birth)

        this.family_id = data.family_id
        this.health = data.health

        this.office_information = data.office_information
        this.office_information.registration_date = this.office_information.registration_date ? new DateTime(this.office_information.registration_date) : null
        this.office_information.admission_date = this.office_information.admission_date ? new DateTime(this.office_information.admission_date) : null
        this.office_information.immigration_status.arrival_date = this.office_information.immigration_status.arrival_date ? new DateTime(this.office_information.immigration_status.arrival_date) : null

        this.documents = data.documents
        this.documents.forEach((document) => {document.date = new DateTime(document.date)})

        this.form_data = data.form_data
        this.donation = data.donation
        this.consent = data.consent
        this.uniform = data.uniform
        this.forms = data.forms   
    }

    static getName(student?: Student) {
        return student?.profile?.first_name + " " + student?.profile?.last_name;
    }
}

export interface StudentForm {
    form_name: string,
    data: any,
    signature: Signature,
}

export interface Signature {
    document_id: ObjectId,
    signed_by: ObjectId,
}

export interface StudentUniform {
    gym_size: string,
    gym_quantity: number,
    day_size: string,
    day_quantity: number,
}

export interface SensitiveStudent {
    _id: ObjectId,
    financial: Financial
}

export interface MediaConsent {
    interview_for_school_publication: boolean,
    interview_for_public_media: boolean,
    photo_video_for_school_publication: boolean,
    photo_video_for_public_media: boolean,
    photo_video_for_social_media: boolean,
    no_permission: boolean
}

export interface Consent {
    media: MediaConsent,
    school_trip: boolean
}

export interface Donation {
    donation: string
}

export interface Financial {
    bank_account_name: string,
    bank_account_number: string,
    bank_transit_number: string,
    financial_institution_number: string,
    tuition_cost: string,
    tuition_payments: string,
    first_time_fee: string,
    student_sponsorship: string
}
export interface StudentProfile{
    first_name: string,
    last_name: string,
    gender: string,
    grade: string,
    date_of_birth: DateTime,
    OEN: string
}

export enum StudentStatus {
    Applicant = "Applicant",
    Processed = "Processed",
    Rejected = "Rejected",
    Enrolled = "Enrolled",
    ReRegistration = "ReRegistration",
    Withdrawn = "Withdrawn"
}

export interface Health {
    health_num: string,
    doc_name: string,
    doc_num: string,
    medical_conditions: string,
    diet: string,
    medications: string,
    learning_ability: string,
    allergies: string,
    allergies_reactions: string
}


export interface OfficeInformation {
    registration_date: DateTime | null,
    admission_date: DateTime | null,
    immigration_status: ImmigrationStatus,
    previous_school: PreviousSchool
}

export interface ImmigrationStatus{
    born_in_canada: boolean,
    birth_country: string,
    status_in_canada: string,
    arrival_date: DateTime | null
}

export interface PreviousSchool{
    previous_school_name: string,
    previous_school_address: string,
    previous_school_phone: string,
    previous_school_email: string
}

export enum DocumentType {
    Other = "Other",
    ProofOfAddress = "ProofOfAddress",
    HealthRecord = "HealthRecord",
    LegalDocument = "LegalDocument",
    EducationDocument = "EducationDocument",
    Signature = "Signature"
}

export interface Document {
    _id: ObjectId,
    name: string,
    document_type: DocumentType,
    date: DateTime
}


export enum FormDataStatus {
    None = "None",
    Waiting = "Waiting",
    Submitted = "Submitted",
    NeedsChange = "NeedsChange",
    Approved = "Approved"
}

interface FormDataDocumentsCheckList {
    immunization_record: boolean,
    citizenship_passport_PR: boolean,
    proof_of_address: boolean,
    education_documents: boolean,
    health_documents: boolean,
    legal_documents: boolean,
    void_check: boolean
}

export interface FormDataDocuments {
    status: FormDataStatus,
    comment: string,
    check_list: FormDataDocumentsCheckList
}

export interface FormDataObj {
    status: FormDataStatus,
    comment: string
}

export interface FormDataInterface {
    documents: FormDataDocuments,
    profile: FormDataObj,
    financial: FormDataObj,
    health: FormDataObj,
    family: FormDataObj,
    office_information: FormDataObj,
    consent: FormDataObj,
    uniform: FormDataObj
    donation: FormDataObj
}

export const Grades = new Map<string, string>([
    ["", ""],
    ["pk", "Pre K"],
    ["jk", "Junior Kindergarten"],
    ["sk", "Senior Kindergarten"],
    ["grade1", "Grade 1"],
    ["grade2", "Grade 2"],
    ["grade3", "Grade 3"],
    ["grade4", "Grade 4"],
    ["grade5", "Grade 5"],
    ["grade6", "Grade 6"],
    ["grade7", "Grade 7"],
    ["grade8", "Grade 8"],
    ["grade9", "Grade 9"],
    ["grade10", "Grade 10"],
    ["grade11", "Grade 11"],
    ["grade12", "Grade 12"]
])

export const GradesArray = Array.from(Grades.values());

export const getGradeKeyFromValue = (searchValue: string): string | undefined => {
	for (let [key, value] of Grades.entries()) {
		if (value === searchValue) {
			return key
		}
	}
	return undefined // Return undefined if the value is not found
}
