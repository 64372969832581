import React, { useState } from 'react';
import { Button } from '@chakra-ui/react';
import { pdf } from '@react-pdf/renderer';
import { saveAs } from 'file-saver';
import { ObjectId } from '../../utils/ObjectId';
import { StudentAPI } from '../../api/StudentAPI';
import { ReportAPI } from '../../api/ReportAPI';
import { ReportCardPdf } from './ReportCardPdf';
import { ReportType } from 'protobuffer-ts/dist/class_service/semester';
import { ReportEntry, ReviewStatus } from '../../models/report_entry';
import { Student } from '../../models/student';
import { AiOutlineFilePdf } from 'react-icons/ai';
import { SemesterAPI } from '../../api/SemesterAPI';
import { CourseAPI } from '../../api/CourseAPI';
import { ReportLayout } from 'protobuffer-ts/dist/class_service/report_layout';
import { proto_utils } from '../../utils/proto_utils';
import { TeacherAPI } from '../../api/TeacherAPI';
import { AttendanceAPI } from '../../api/AttendanceAPI';
import { PdfAttendanceCount } from '../../models/attendance';

interface PdfGeneratorProps {
  student_id: ObjectId;
  semester_id: ObjectId;
  report_type: ReportType;
  }

export const PdfGenerator: React.FC<PdfGeneratorProps> = ({ student_id, semester_id, report_type }) => {
  const [loading, setLoading] = useState(false);

  const generatePdf = async () => {
    setLoading(true);
  
    try {
      // Fetch student, semester, and courses
      const [student, semester, { courses }] = await Promise.all([
        StudentAPI.get_student(student_id.$oid),
        SemesterAPI.get_semester(semester_id),
        CourseAPI.course_list(null, null, undefined, undefined, undefined, true, semester_id),
      ]);
    
      // Fetch report entries for all courses
      const coursesReports = await Promise.all(
        courses.map((course) =>
          ReportAPI.get_student_report_entries_for_course(student_id, course._id)
        )
      );
    
      // Filter by report type and keep only published report entries
      const publishedReports = coursesReports
        .flatMap((res: ReportEntry[]) => res.filter((r) => r.report_type === report_type && r.review_status === ReviewStatus.Published));
    
      // filter courses based on published report entries
      const publishedCourses = courses.filter((course) =>
        publishedReports.some((report) => report.course?.$oid === course._id.$oid)
      );
    
      // Fetch layouts, attendance, and teachers for only published courses
      const [reportLayouts, attendanceData, teachers] = await Promise.all([
        Promise.all(
          publishedCourses.map((course) => CourseAPI.get_report_layout(course._id))
        ),
        Promise.all(
          publishedCourses.map(async (course) => {
            const selectedReportEndDate = semester.reportLayout!.reportDates.find(
              (r) => r.reportType === report_type
            )!.distributionDate!.toISOString();
    
            const attendanceCountPayload = {
              course_id: course._id,
              student_id,
              start_date: semester.startDate!.toISOString(),
              end_date: selectedReportEndDate,
            };
    
            const days_count = await AttendanceAPI.get_student_course_attendance_count(attendanceCountPayload);
    
            return { courseId: course._id.$oid, days_count };
          })
        ),
        TeacherAPI.teachers_by_ids(
          Array.from(
            new Set(publishedCourses.flatMap((course) => course.teachers))
          )
        ).catch(() => undefined),
      ]);
    
      // Transform attendance data into a Record
      const attendanceCourses = attendanceData.reduce((acc, { courseId, days_count }) => {
        acc[courseId] = { total_days_count: {}, days_count };
        return acc;
      }, {} as Record<string, PdfAttendanceCount>);
    
      // Extract semester date
      const semesterDate = semester.reportLayout?.reportDates.find(
        (r) => r.reportType === report_type
      )?.distributionDate;
    
      // Generate the PDF document
      const document = (
        <ReportCardPdf
          student={student!}
          semester={semester}
          reportType={report_type}
          semesterDate={semesterDate}
          courses={publishedCourses}
          teachers={teachers}
          reportEntries={publishedReports}
          reportLayouts={reportLayouts}
          attendanceCourses={attendanceCourses}
        />
      );
    
      const asBlob = await pdf(document).toBlob();
      saveAs(asBlob, `${Student.getName(student!)} ${semester.name} Report.pdf`);
    } catch (error) {
      console.error("Error generating PDF:", error);
    } finally {
      setLoading(false);
    }
  }
    
  
  return (
    <Button colorScheme="teal" onClick={generatePdf} isLoading={loading} leftIcon={<AiOutlineFilePdf />}>
      {loading ? 'Generating PDF...' : `Export PDF`}
    </Button>
  );
};
