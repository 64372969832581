import {Box,
    Center,
    Heading,
    Tab,
    TabList,
    TabPanel,
    TabPanels,
    Tabs
} from "@chakra-ui/react";
import React from "react";
import {SemesterList} from "../semesters/SemesterList";
import {NotificationList} from "../notifications/NotificationsList";
import {Migrations} from "../migrations/Migrations";
import {AiInteractionRecordList} from "../ai_interaction_record/AiInteractionRecordsList";

export const DeveloperPortal = React.memo(() =>  {

    return (<Box>
            <Center>
                <Heading size="md" mb="6">
                    Developer Portal
                </Heading>
            </Center>
            <Tabs >
                <TabList>
                    <Tab>Migrations</Tab>
                    <Tab>Ai Interaction Records</Tab>
                </TabList>
                <TabPanels>
                    <TabPanel>
                        <Migrations/>
                    </TabPanel>
                    <TabPanel>
                        <AiInteractionRecordList/>
                    </TabPanel>
                </TabPanels>
            </Tabs>
        </Box>

    );
});
