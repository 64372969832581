import { ObjectId } from "../utils/ObjectId";
import { DateTime } from "../utils/DateTime";
import {UserType} from "../api/UserApi";

export interface Attendance {
    _id: ObjectId,
    student: ObjectId,
    course: ObjectId | null,
    homeroom: ObjectId | null,
    date: DateTime,
    reason: string,
    sign_in_time: DateTime | null,
    sign_out_time: DateTime | null,
    status: string,
    late_dismissal_date?: DateTime,
    student_excused_by?: ObjectId,
    student_excused_by_user_type?: UserType
    period_number: number
}

export interface AttendanceCount {
    course_id?: ObjectId, 
    homeroom_id?: ObjectId, 
    student_id: ObjectId, 
    start_date: string, 
    end_date: string
}

export const AttendanceColors: Record<string, string> = {
    'Present': 'green',
    'Late': 'orange',
    'Absent': 'red'
};


export interface PdfAttendanceCount {
    days_count:  { [key: string]: number }
    total_days_count:  { [key: string]: number }
}