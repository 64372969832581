import React, { useEffect, useState } from "react";
import { Stack, useToast } from '@chakra-ui/react';
import { ReportEntry } from "../../models/report_entry";
import { ReportAPI } from "../../api/ReportAPI";
import { StudentReportEntryView } from "../reports/StudentReportEntryView";
import { Student } from "../../models/student";
import GClassViewerPlaceholder from "../gclass/GClassViewerPlaceholder";
import { Lookup } from "../../models/lookup";

interface Props {
	student: Student
}
export const StudentPublishedReports = React.memo(({student} : Props) => {
	const toast = useToast()

	const [report_entries, set_report_entries] = useState< ReportEntry[]>([]);

	const [loading, set_loading] = useState<boolean>(true);

	const fetchAllData = async () => {
		set_loading(true)

		ReportAPI.get_student_published_report_entries(student._id.$oid)
			.then((res : ReportEntry[]) => {
				// TEMP FIX: I need to place homeroom entries to be first
				let homeroom_entries = res.filter(e => e.homeroom != null)
				let course_entires = res.filter(e => e.course != null)
				let entries = [...homeroom_entries, ...course_entires]
				set_report_entries(entries)
			}).catch((err) => {
				toast({
					title: 'Failed to Retrive Entries.',
					description: err.response.data,
					status: 'error',
					duration: 5000,
					isClosable: true,
				});
			}).finally(() => {
				set_loading(false)
			})
	}

	useEffect(() => {
		fetchAllData().then()
	}, [])

    return (
		<>
		{ loading ? <GClassViewerPlaceholder /> :
		<Stack>
			{ report_entries.map((entry, index) => 
				<StudentReportEntryView
					key={index}
					report_entry={entry}
				/>
			)}
		</Stack>
		}
		</>
    );
});
