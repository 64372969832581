import {ObjectId} from "../utils/ObjectId";

export enum Status {
    Pending = "Pending",
    Processed = "Processed",
    Error = "Error"
}

export class AiInteractionRecord {
    _id: ObjectId;
    organization: ObjectId;
    user_id: ObjectId;
    prompt: string;
    response: string;
    status: Status;

    constructor(data: any) {
        this._id = data._id;
        this.organization = data.organization;
        this.user_id = data.user_id;
        this.prompt = data.prompt;
        this.response = data.response;
        this.status = Status[data.status as keyof typeof Status];
    }
}