import HTTPApi from "./ApiCommon";
import { ObjectId } from "../utils/ObjectId";
import { AiInteractionRecord } from "../models/ai_interaction_record";;

export namespace AiInteractionRecordAPI {
    export interface AiInteractionRecordList {
        ai_interaction_record: Array<AiInteractionRecord>,
        ai_interaction_record_count: number
    }

    export const ai_interaction_record_list = async (
        per_page: number | null = null,
        page: number | null = null,
        name_search: string | undefined = undefined,
        archived: boolean | undefined = undefined
    ): Promise<AiInteractionRecordList | void> => {
        return HTTPApi.post(
            "ai_interaction_record/ai_interaction_records_list", {
                per_page: per_page,
                page: page,
                name_search: name_search,
                archived: archived,
            })
            .then((res) => {
                return {
                    ai_interaction_record: res.data.ai_interaction_record.map((ai_interaction_record: any) => {
                        return new AiInteractionRecord(ai_interaction_record);
                    }),
                    ai_interaction_record_count: res.data.ai_interaction_record_count
                } as AiInteractionRecordList;
            })
            .catch((e) => {
                console.log(e);
            });
    }

    export const get_ai_interaction_record = async (ai_interaction_record_id: ObjectId) => {
        return HTTPApi.get("/ai_interaction_record/" + ai_interaction_record_id.$oid)
            .then((res) => {
                return new AiInteractionRecord(res.data);
            });
    }
}