import React from 'react';
import { View, Text as PdfText } from '@react-pdf/renderer';
import { Course } from 'protobuffer-ts/dist/class_service/course';
import { ReportEntry } from '../../models/report_entry';
import PdfStylesheet from './PdfStylesheet';

type CourseProps = {
  course: Course;
  reportEntry: ReportEntry;
};

const styles = PdfStylesheet

export const CourseRowPdf: React.FC<CourseProps> = ({ course, reportEntry }) => {
  return (
    <View style={styles.gridContainer}>
      {/* Subject Name and Checkboxes */}
      <View style={[styles.gridCell, styles.subjectCol, { padding: 0 }]}>
        <PdfText style={styles.subjectText}>{course.name}</PdfText>
        <View style={{ flexDirection: 'row', flexWrap: 'wrap' }}>
          {course.reportLayout?.checkBoxes?.map(({ name, id }, i) => (
            <View key={i} style={{ marginLeft: 5, flexDirection: 'row', alignItems: 'center' }}>
              <PdfText style={styles.value}>{reportEntry.checkboxes?.find(c => c.check_box_id.$oid === id?.id)?.state ? '✔️' : ''}</PdfText>

              <PdfText style={styles.value}>{` ${name}`}</PdfText>
            </View>
          ))}
        </View>
      </View>

      {/* Progress Levels */}
      {['Progressing with Difficulty', 'Progressing Well', 'Progressing Very Well'].map((_checkbox, i) => (
        <View key={i} style={[styles.gridCell, styles.smallCol]}>
          <PdfText style={[styles.value, { textAlign: 'center' }]}>
            {reportEntry.sections?.some((section) => section.mark === _checkbox) ? '✔️' : ''}
          </PdfText>
        </View>
      ))}

      {/* Comments */}
      <View style={[styles.gridCell, styles.largeCol, { height: 125, fontSize: 7 }]}>
        <PdfText style={styles.value}>{reportEntry.comment}</PdfText>
      </View>
    </View>
  );
};

