import React, {useEffect, useState} from "react";
import {
    Badge,
    Box,
    FormControl,
    HStack,
    Input,
    InputGroup,
    InputLeftElement,
    Stack, Text,
    usePrevious,
} from "@chakra-ui/react";
import {useLocation} from "react-router-dom";
import {TableContent} from "../table/TableContent";
import {BsSearch} from "react-icons/bs";
import {TablePagination} from "../table/TablePagination";
import {AiInteractionRecordAPI} from "../../api/AiInteractionRecordAPI";
import LocalStorageService from "../../api/LocalStorageService";
import {ObjectId} from "../../utils/ObjectId";
import {Status} from "../../models/ai_interaction_record";
import {Evaluation} from "../../models/evaluation";

export const AiInteractionRecordList = () => {
    const PER_PAGE = 100;
    const location = useLocation();
    const [ai_interaction_recordsData, setAiInteractionRecordsData] = useState<Array<AiInteractionRecordObj>>([]);
    const [ai_interaction_recordsPage, setAiInteractionRecordsPage] = useState<number>(1);
    const [ai_interaction_recordsMaxPage, setAiInteractionRecordsMaxPage] = useState<number>(0);
    const [totalAiInteractionRecords, setTotalAiInteractionRecords] = useState<number>(0);
    const [ai_interaction_recordTitleSearch, setAiInteractionRecordTitleSearch] = useState<string>("");
    const [loading, setLoading] = useState<boolean>(true);

    const previousSearch = usePrevious(ai_interaction_recordTitleSearch);

    const user = LocalStorageService.getInstance().getUser();

    const _setAiInteractionRecordList = (res: AiInteractionRecordAPI.AiInteractionRecordList) => {
        setAiInteractionRecordsData(
            res.ai_interaction_record.map((ai_interaction_record) => ({
                _id: ai_interaction_record._id.$oid,
                organization: ai_interaction_record.organization.$oid,
                user_id: ai_interaction_record.user_id.$oid,
                prompt: ai_interaction_record.prompt,
                response: ai_interaction_record.response,
                status: ai_interaction_record.status.toString(),
                link: `${location.pathname}/ai_interaction_record/${ai_interaction_record._id.$oid}`,
            }))
        );

        setTotalAiInteractionRecords(res.ai_interaction_record_count);
        setAiInteractionRecordsMaxPage(Math.ceil(res.ai_interaction_record_count / PER_PAGE));
    };

    const setAiInteractionRecordList = async (_page?: number, title_search?: string) => {
        setLoading(true);

        const res = await AiInteractionRecordAPI.ai_interaction_record_list(PER_PAGE, _page, title_search, false);
        if (res) {
            console.log("Yazan");
            console.log(res);
            _setAiInteractionRecordList(res);
        } else {
            console.warn("Received undefined response for ai_interaction_records.");
            // Optionally, reset the ai_interaction_records data and pagination
            setAiInteractionRecordsData([]);
            setTotalAiInteractionRecords(0);
            setAiInteractionRecordsMaxPage(0);
        }

        setLoading(false);
    };

    useEffect(() => {
        if (previousSearch !== ai_interaction_recordTitleSearch) {
            setAiInteractionRecordsPage(1);
        }

        setAiInteractionRecordList(ai_interaction_recordsPage, ai_interaction_recordTitleSearch);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [ai_interaction_recordsPage, ai_interaction_recordTitleSearch]);

    const getAiInteractionRecordColumns = () => {
        const columns = [
            {
                Header: "ID",
                accessor: "_id",
            },
            {
                Header: "Status",
                accessor: "status",
            },
            {
                Header: "Date",
                accessor: "created_on",
            },
            {
                Header: "Response",
                accessor: "response",
                Cell: function StatusCell(response: string) {
                    return (<>{response.slice(0, 75)}</>);
                },
            }
        ];

        return columns;
    };

    return (
        <Box>
            <Stack>
                <Stack spacing="4" direction={{ base: "column", md: "row" }} justify="space-between">
                    <HStack>
                        <FormControl minW={{ md: "320px" }} id="search">
                            <InputGroup size="sm">
                                <InputLeftElement pointerEvents="none" color="gray.400">
                                    <BsSearch />
                                </InputLeftElement>
                                <Input
                                    rounded="base"
                                    type="search"
                                    placeholder="Search by response..."
                                    value={ai_interaction_recordTitleSearch}
                                    onChange={(e) => setAiInteractionRecordTitleSearch(e.target.value)}
                                />
                            </InputGroup>
                        </FormControl>
                    </HStack>
                </Stack>

                <TableContent loading={loading} data={ai_interaction_recordsData} columns={getAiInteractionRecordColumns()} />
                <TablePagination
                    text={`Showing ${PER_PAGE * ai_interaction_recordsPage > totalAiInteractionRecords ? totalAiInteractionRecords : PER_PAGE * ai_interaction_recordsPage} of ${totalAiInteractionRecords}`}
                    set_page={setAiInteractionRecordsPage}
                    page={ai_interaction_recordsPage}
                    max_page={ai_interaction_recordsMaxPage}
                />
            </Stack>
        </Box>
    );
};

export interface AiInteractionRecordObj {
    _id: string;
    organization: string;
    user_id: string;
    prompt: string;
    response: string;
    status: string;
    link?: string;
}