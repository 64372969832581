import React from 'react';
import { View, Text as PdfText } from '@react-pdf/renderer';
import PdfStylesheet from './PdfStylesheet';
import { CourseAPI } from '../../api/CourseAPI';
import { ReportEntry } from '../../models/report_entry';
import { ReportLayout } from 'protobuffer-ts/dist/class_service/report_layout';
import { Semester } from 'protobuffer-ts/dist/class_service/semester';
import { learningSkills } from '../../models/semester';
import { PdfAttendanceCount } from '../../models/attendance';

type CourseProps = {
  semester: Semester;
  course: CourseAPI.ListCourse;
  teacher?: string,
  reportEntry: ReportEntry;
  reportLayout: ReportLayout;
  attendanceCount: PdfAttendanceCount;
};

const styles = PdfStylesheet

export const CourseRowPdf: React.FC<CourseProps> = ({ semester, course, teacher, reportEntry, reportLayout, attendanceCount}) => {
  console.log(attendanceCount);
  // Homeroom Learning Skills Data
	const learningSkillsMap: { [key: string]: string } = {};
	semester.reportLayout?.learningSkills.forEach((skill) => {
		if (skill.id) {
			learningSkillsMap[skill.id.id] = skill.title;
		}
	});

	// Mapping Learning Skills to their Values in Desired Format
	const grades: { [key: string]: string } = {};
	reportEntry.learning_skills.forEach((learningSkill) => {
		const skillId = learningSkill.learning_skill_id.$oid;
		const skillTitle = learningSkillsMap[skillId];
		const skillValue = learningSkill.mark;
		grades[skillTitle] = skillValue;
	});

  // Find the section in the report layout that matches the course name
  const section = reportLayout.sections.find((section) => section.name === course.name);

  // If the section exists, find the corresponding mark
  const mark = section
    ? reportEntry.sections.find((entry) => entry.layout_id.$oid === section.id?.id)?.mark
    : undefined;


  return (
    <View style={styles.gridContainer}>
      {/* Course Name */}
      <View style={[styles.gridCell, styles.subjectCol, { padding: 0 }]}>
        <PdfText style={styles.subjectText}>{course.name}</PdfText>

        <PdfText style={styles.courseRowValue}>Course Code: {course.course_code}</PdfText>

        <PdfText style={styles.courseRowValue}>Teacher: {teacher}</PdfText>

        <View style={{ flexDirection: 'row', flexWrap: 'wrap', marginTop: 5 }}>
          {reportLayout.checkBoxes?.map(({ name, id }, i) => (
            <View key={i} style={{ flexDirection: 'row', alignItems: 'center' }}>
              <PdfText style={styles.courseRowValue}>{reportEntry.checkboxes?.find(c => c.check_box_id.$oid === id?.id)?.state ? '✔️' : ''}</PdfText>

              <PdfText style={styles.courseRowValue}>{` ${name}`}</PdfText>
            </View>
          ))}
        </View>
      </View>

      <View style={[styles.smallCol]}>
        <View style={[styles.gridCell, styles.cellValue, styles.periodLabel, {paddingBottom: 10}]}>
          <PdfText style={[styles.verticalText]}>First</PdfText>
        </View>

        <View style={[styles.gridCell, styles.cellValue, styles.periodLabel, {paddingBottom: 10}]}>
          <PdfText style={[styles.verticalText]}>Final</PdfText>
        </View>
      </View>

      <View style={[styles.smallCol]}>
        <View style={[styles.gridCell, styles.cellValue]}>
          <PdfText>{mark}</PdfText>
        </View>

        <View style={[styles.gridCell, styles.cellValue]}></View>
      </View>

      <View style={[styles.smallCol]}>
        <View style={[styles.gridCell, styles.cellValue]}>
          <PdfText></PdfText>
        </View>

        <View style={[styles.gridCell, styles.cellValue]}></View>
      </View>

      <View style={[styles.smallCol]}>
        <View style={[styles.gridCell, styles.cellValue]}>
          <PdfText></PdfText>
        </View>

        <View style={[styles.gridCell, styles.cellValue]}></View>
      </View>

      {learningSkills.map(({ skill }) => (
        <View style={[styles.smallCol]}>
          <View style={[styles.gridCell, styles.cellValue, {textAlign: 'center'}]}>
            <PdfText>{grades[skill]?.[0] || 'N/A'}</PdfText>
          </View>

          <View style={[styles.gridCell, styles.cellValue]}></View>
        </View>
      ))}

      {/* Comments */}
      <View style={[styles.gridCell, styles.largeCol]}>
        <PdfText style={styles.courseRowValue}>
          {reportEntry.comment}
        </PdfText>
      </View>

      <View style={[styles.smallCol]}>
        <View style={[styles.gridCell, styles.cellValue]}>
          <PdfText style={{textAlign: 'center'}}>{attendanceCount.days_count['Absent'] ?? '0'}</PdfText>
        </View>

        <View style={[styles.gridCell, styles.cellValue]}></View>
      </View>
      <View style={[styles.smallCol]}>
        <View style={[styles.gridCell, styles.cellValue]}>
        <PdfText style={{textAlign: 'center'}}>{attendanceCount.days_count['Late'] ?? '0'}</PdfText>
        </View>

        <View style={[styles.gridCell, styles.cellValue]}></View>
      </View>

      <View style={[styles.smallCol]}>
        <View style={[styles.gridCell, styles.cellValue]}>
          <PdfText style={{textAlign: 'center'}}>{
            (attendanceCount.days_count['Absent'] ?? 0) + 
            (attendanceCount.days_count['Late'] ?? 0) + 
            (attendanceCount.days_count['Present'] ?? 0)
            }</PdfText>
        </View>

        <View style={[styles.gridCell, styles.cellValue]}></View>
      </View>
    </View>
  );
};

