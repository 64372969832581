
import { formatDistance } from "date-fns";
import { SemesterReportLayout, ReportDates, Semester, ReportType } from "protobuffer-ts/dist/class_service/semester";
import { ObjectId, to_oid } from "../utils/ObjectId";

export class SemesterUpdate {
    _id: ObjectId;
    name: string;
    start_date: Date;
    end_date: Date;
    report_layout: SemesterReportLayout;

    constructor(data: Semester) {
        this._id = to_oid(data.id!.id)
        this.name = data.name;
        this.start_date = data.startDate!;
        this.end_date = data.endDate!;
        this.report_layout = SemesterReportLayout.fromJSON(data.reportLayout);
    }
}


export interface CreateSemester {
    name: string;
    start_date: Date;
    end_date: Date;
}

export const gerReportDate = (reportDates: ReportDates[] = [], reportType: ReportType | undefined, dateType: 'dueDate' | 'distributionDate'): string | undefined => {
    const date = reportDates.find(date => date.reportType === reportType)?.[dateType]

    if (date) return formatDistance(new Date(date), new Date(), { addSuffix: true})
};

export const learningSkillsOptions = [
    "Responsibility",
    "Organization",
    "Independent Work",
    "Initiative",
    "Collaboration",
    "Self-Regulation",
]

export const learningSkillValues = ["Excellent", "Good", "Satisfactory", "Needs Improvement"]

export const learningSkills  = [
	{
		skill: 'Responsibility',
		descriptions: [
			'Fulfils responsibilities and commitments within the learning environment.',
			'Completes and submits class work, homework, and assignments according to agreed-upon timelines.',
			'Takes responsibility for and manages own behaviour.',
		],
	},
	{
		skill: 'Organization',
		descriptions: [
			'Devises and follows a plan and process for completing work and tasks.',
			'Establishes priorities and manages time to complete tasks and achieve goals.',
			'Identifies, gathers, evaluates, and uses information, technology, and resources to complete tasks.',
		],
	},
	{
		skill: 'Independent Work',
		descriptions: [
			'Independently monitors, assesses, and revises plans to complete tasks and meet goals.',
			'Uses class time appropriately to complete tasks.',
			'Follows instructions with minimal supervision.',
		],
	},
	{
		skill: 'Collaboration',
		descriptions: [
			'Accepts various roles and an equitable share of work in a group.',
			'Responds positively to the ideas, opinions, values, and traditions of others.',
			'Builds healthy peer-to-peer relationships through personal and media-assisted interactions.',
			'Works with others to resolve conflicts and build consensus to achieve group goals.',
			'Shares information, resources, and expertise, and promotes critical thinking to solve problems and make decisions.',
		],
	},
	{
		skill: 'Initiative',
		descriptions: [
			'Looks for and acts on new ideas and opportunities for learning.',
			'Demonstrates the capacity for innovation and a willingness to take risks.',
			'Demonstrates curiosity and interest in learning.',
			'Approaches new tasks with a positive attitude.',
			'Recognizes and advocates appropriately for the rights of self and others.',
		],
	},
	{
		skill: 'Self-Regulation',
		descriptions: [
			'Sets own individual goals and monitors progress towards achieving them.',
			'Seeks clarification or assistance when needed.',
			'Assesses and reflects critically on own strengths, needs, and interests.',
			'Identifies learning opportunities, choices, and strategies to meet personal needs and achieve goals.',
			'Perseveres and makes an effort when responding to challenges.',
		],
	},
];
