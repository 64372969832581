import { StyleSheet } from "@react-pdf/renderer";

export default StyleSheet.create({
	page: {
		padding: 10,
		paddingVertical: 0,
		fontSize: 10,
		lineHeight: 1.4,
		flexDirection: 'column',
		fontFamily: 'Roboto',
	},
	flexCenter: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center'
	},
	headerContainer: {
		flexDirection: 'row',
		justifyContent: 'space-between',
		alignItems: 'center',
	},
	headerColumn: {
		width: '33%',
	},
	logo: {
		width: 60,
		height: 60,
		objectFit: "contain"
	},
	header: {
		fontSize: 16,
		textAlign: 'center',
		fontWeight: 'bold',
	},
	sectionTitleContainer: {
		backgroundColor: '#dbdbf2',
		padding: 2,
		borderTop: '1px solid #000',
		borderLeft: '1px solid #000',
		borderRight: '1px solid #000',
	},
	sectionTitleText: {
		fontSize: 11,
		fontWeight: 'bold',
		color: '#000',
		textAlign: 'center',
	},

	headerSemester: {
		display: 'flex',
		flexDirection: 'row',
		justifyContent: "flex-end"
	},

	tableContainer: {
		display: 'flex',
		flexDirection: 'row',
		flexWrap: 'wrap',
		borderTop: '1px solid #000',
		borderLeft: '1px solid #000',
	},
	border: {
		border: '1px solid #000',
	},
	cell: {
		borderRight: '1px solid #000',
		borderBottom: '1px solid #000',
		padding: 5,
		backgroundColor: '#f7f7ff',
	},
	cellHalfWidth: {
		width: '50%',
	},
	cellQuarterWidth: {
		width: '25%',
	},
	cellFullWidth: {
		width: '100%',
	},
	label: {
		fontWeight: 'bold',
		fontSize: 9,
	},
	value: {
		fontSize: 9,
	},
	courseRowValue: {
		fontSize: 7,
		marginLeft: 5,
	},

	skillsHeaderContainer: {
		backgroundColor: '#dbdbf2',
		padding: 2,
		border: '1px solid #000',
	},
	skillsSubHeaderContainer: {
		backgroundColor: '#f7f7ff',
		padding: 2,
		borderLeft: '1px solid #000',
		borderRight: '1px solid #000',
		borderBottom: '1px solid #000',
	},
	skillsHeaderText: {
		fontSize: 11,
		fontWeight: 'bold',
		color: '#000',
		textAlign: 'center'
	},
	skillsSubHeaderText: {
		fontSize: 9,
		color: '#000',
		textAlign: 'center'
	},
	skillsColumnContainer: {
		borderLeft: '1px solid #000',
		flexDirection: 'row',
		flexWrap: 'wrap',
	},
	skillItemContainer: {
		width: '50%',
		borderBottom: '1px solid #000',
		borderRight: '1px solid #000',
		backgroundColor: '#f7f7ff',
	},
	skillItem: {
		flexDirection: 'row',
		justifyContent: 'space-between',
		alignItems: 'center',
	},
	skillTitle: {
		backgroundColor: '#dbdbf2',
		paddingHorizontal: 8,
		paddingVertical: 4,
		fontSize: 10,
		fontWeight: 'bold',
		borderBottom: '1px solid #000',
		borderRight: '1px solid #000',
	},
	skillGrade: {
		backgroundColor: '#ffffff',
		paddingHorizontal: 8,
		paddingVertical: 4,
		fontSize: 10,
		borderBottom: '1px solid #000',
		borderLeft: '1px solid #000',
	},
	skillDescriptionContainer: {
		fontSize: 8,
		lineHeight: 1.3,
		padding: 5,
		height: 100
	},
	bulletPoint: {
		marginBottom: 2,
	},
	sectionSpacing: {
		marginBottom: 10,
	},
	commentSection: {
		borderLeft: '1px solid #000',
		borderRight: '1px solid #000',
		borderBottom: '1px solid #000',
		padding: 10,
		fontSize: 9,
		lineHeight: 1.4,
		backgroundColor: '#f7f7ff',
		height: 100
	},
	footer: {
		position: 'absolute',
		bottom: 10,
		left: 20,
		right: 20,
		flexDirection: 'row',
		fontSize: 8,
		borderTop: '1px solid #000',
		paddingTop: 5,
	},
	footerColumnLeft: {
		flexDirection: 'column',
		alignItems: 'flex-start',
		width: '33%',
	},
	footerColumnCenter: {
		flexDirection: 'column',
		alignItems: 'center',
		width: '33%',
	},
	footerColumnRight: {
		flexDirection: 'column',
		alignItems: 'flex-end',
		width: '33%',
	},
	verticalText: {
		transform: 'rotate(-90deg)',
		transformOrigin: 'top left',
		fontSize: 7,
		position: 'relative',
		left: 0,
		top: '100%', // Adjust the starting position for top-to-bottom alignment
	},
	gridContainer: {
		backgroundColor: '#f7f7ff',
		display: 'flex',
		flexDirection: 'row',
		flexWrap: 'wrap',
		borderLeft: '1px solid #000',
	},
	gridCell: {
		fontSize: 9,
		borderRight: '1px solid #000',
		borderBottom: '1px solid #000',
		padding: 5,
	},
	cellValue: {
		height: 35,
	},
	periodLabel: {
		backgroundColor: "#dbdbf2"
	},
	subjectCol: {
		width: '15%',
	},
	smallCol: {
		width: '2.5%',
	},
	SemesterCol: {
		width: '16.666%',
		height: 64,
	},
	AttendanceCol: {
		width: '33.333%',
		height: 64,
	},
	largeCol: {
		width: '52.499%',
	},
	learningSkillsLabel: {
		backgroundColor: '#f7f7ff',
		fontSize: 7,
		padding: 3,
		textAlign: 'center',
	},
	subjectText: {
		paddingHorizontal: 8,
		paddingVertical: 4,
		fontSize: 10,
		backgroundColor: '#dbdbf2',
		borderBottom: '1px solid #000',
		marginBottom: 5
	}
});